* {
  outline: none !important;
}

html {
  font-size: 62.5% !important; /* 62.5% of 16px = 10px */
  min-height: 100vh;
}

body {
  min-height: 100vh;
}

#__next {
  min-height: 100vh;
}

.max-w-400 {
  max-width: 400px;
}

.Toastify__toast {
  min-height: auto;
}

.Toastify__toast-body {
  display: flex;
  margin: 0;
  padding: 8px;
  font-weight: 500;
  font-family: 'Nunito Sans', sans-serif;
  align-items: center;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
  box-shadow: rgb(29 45 80) !important;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.9;
}
